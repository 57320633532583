import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import './Workout.css';
import './WorkoutSet.css'
import {
    addSetToUserWorkout,
    updateSetInUserWorkout,
    deleteSetInUserWorkout,
    addTemplateSetToUserWorkout,
    updateSetInTemplateUserWorkout,
    deleteSetInTemplateUserWorkout
} from '../../api/APIManager';

function WorkoutSet({ type, workout }) {
    const [sets, setSets] = useState([]);

    function isProgramTemplateWOrkoutSet() {
        if (type === 'template') {
            return true;
        }
        return false;
    }

    function isClientWorkoutSet() {
        if (type === 'client') {
            return true;
        }
        return false;
    }

    const handleAddSet = async () => {
        const newSet = { setWeight: '', actualReps: '', repGoal: '' };
        setSets([...sets, newSet]);

        if (isProgramTemplateWOrkoutSet()) {
            await addTemplateSetToUserWorkout(workout._id, newSet);
        } else {
            await addSetToUserWorkout(workout._id, newSet);
        }
    };

    const handleSetChange = async (index, field, value) => {
        const updatedSets = sets.map((set, i) => {
            if (i === index) {
                return { ...set, [field]: value };
            }
            return set;
        });
        setSets(updatedSets);

        if (isProgramTemplateWOrkoutSet()) {
            await updateSetInTemplateUserWorkout(workout._id, updatedSets[index]._id, updatedSets[index]);
        } else {
            await updateSetInUserWorkout(workout._id, updatedSets[index]._id, updatedSets[index]);
        }
    };

    const handleDeleteSet = async (index) => {
        const setToDelete = sets[index]
        const updatedSets = sets.filter((set, i) => i !== index);
        setSets(updatedSets);

        if (isProgramTemplateWOrkoutSet()) {
            await deleteSetInTemplateUserWorkout(workout._id, setToDelete._id);
        } else {
            await deleteSetInUserWorkout(workout._id, setToDelete._id);
        }
    };

    useEffect(() => {
        setSets(workout.sets);
        console.log('WorkoutSets from useEffect');
        console.log(workout.sets);

    }, []);

    const showDeleteSetButtonIfAllowed = (index) => {
        if (!isClientWorkoutSet()) {
            return (
                <Button danger onClick={() => handleDeleteSet(index)}>Delete</Button>
            )
        }

        return null
    }

    const showAddSetButtonIfAllowed = () => {
        if (!isClientWorkoutSet()) {
            return (
                <Button warning onClick={handleAddSet}>Add Set</Button>
            )
        }

        return null
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        // Process your form data here, e.g., send to an API
        console.log(sets);
    };

    return (
        <div className="workout-set-container">
            <div className="workout-set-header">
                <div className="workout-set-column"><strong>Set Weight</strong></div>
                <div className="workout-set-column"><strong>Actual Reps</strong></div>
                <div className="workout-set-column"><strong>Rep Goal</strong></div>
                {!isClientWorkoutSet() && (
                    <div className="workout-set-column"><strong> </strong></div>
                )}
            </div>
            {sets.map((set, index) => (
                <div key={set._id} className="workout-set-row">
                    <input
                        type="number"
                        value={set.setWeight}
                        onChange={(e) => handleSetChange(index, 'setWeight', e.target.value)}
                        className="workout-set-input"
                    />
                    <input
                        type="number"
                        value={set.actualReps}
                        onChange={(e) => handleSetChange(index, 'actualReps', e.target.value)}
                        className="workout-set-input"
                    />
                    <input
                        type="number"
                        value={set.repGoal}
                        onChange={(e) => handleSetChange(index, 'repGoal', e.target.value)}
                        className="workout-set-input"
                        readOnly={isClientWorkoutSet()}
                    />
                    {showDeleteSetButtonIfAllowed(index)}
                </div>
            ))}
            {showAddSetButtonIfAllowed()}
        </div>
    );
}

export default WorkoutSet;
