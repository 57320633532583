import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getProgramTemplate,
  createActivityAndAddToProgramTemplate,
  updateProgramTemplate,
  addNewSectionInProgramTemplate,
  duplicateSectionInProgramTemplate,
  updateSectionInProgramTemplate,
  deleteSectionInProgramTemplate,
  deleteProgramTemplate,
  deleteActivityInProgramTemplateSection,
  deleteTemplateUserWorkout
} from '../shared/api/APIManager';
import Button from '../shared/components/Button';
import Modal from 'react-modal'; // Or your custom modal component
import "./TrainerProgramDetailPage.css";
import SectionsList from '../shared/pages/New/SectionList';

function TrainerProgramDetailPage() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewActivityModalOpen, setIsNewActivityModalOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [program, setProgram] = useState({
    title: '',
    description: '',
    sections: [],
  });
  const [sectionTitle, setSectionTitle] = useState('');
  const [newActivity, setNewActivity] = useState({
    title: '',
    workouts: [],
  });

  const { id } = useParams();

  useEffect(() => {
    // Fetch the program by ID from your API
    getProgramTemplate(id)
      .then((response) => {
        setProgram(response.data);
        console.log('Program data: ', response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching the data: ', error);
      });
  }, []);

  const handleChange = (e) => {
    setSectionTitle(e.target.value);
  };

  const handleDeleteActivity = async (activity, sectionID) => {
    const section = program.sections.find((s) => s._id === sectionID);
    if (!section) {
      console.error('Section not found');
      return;
    }

    const activityToDelete = section.activities.find((a) => a._id === activity._id);
    if (!activityToDelete) {
      console.error('Activity not found');
      return;
    }

    // Delete all userworkouts for saved program template
    activityToDelete.workouts.forEach(async (workout) => {
      try {
        const response = await deleteTemplateUserWorkout(workout._id);
        if (response.status === 200) {
          console.log('UserWorkouts for activity deleted successfully');
        } else {
          console.error('Error deleting user workouts for activity');
        }
      } catch (error) {
        console.error('Error deleting user workouts for activity:', error);
      }
    });

    deleteActivityInProgramTemplateSection(program._id, section._id, activityToDelete._id)
      .then((response) => {
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error deleting activity from section to program: ', error);
      });
  }

  const handleSectionTitleEdit = (sectionTitleUpdate, sectionID) => {
    const sectionTitleUpdateData = {
      sectionId: sectionID,
      title: sectionTitleUpdate
    };

    updateSectionInProgramTemplate(program._id, sectionID, sectionTitleUpdateData)
      .then((response) => {
        console.log('Program section updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating new section to program: ', error);
      });
  }

  const handleAddSectionTapped = () => {
    setIsModalOpen(true);
  }

  const handleSectionDuplicate = (section) => {
    program.sections.push(section);

    //(programToUpdate, sectionID)
    duplicateSectionInProgramTemplate(program._id, section._id)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating program: ', error);
      });
  }

  const handleSectionDelete = (sectionToRemove) => {
    const newSections = program.sections.filter(section => section._id !== sectionToRemove._id);
    setProgram({ ...program, sections: newSections });
    let updatedProgram = { ...program, sections: newSections }

    updateProgramTemplate(updatedProgram._id, updatedProgram)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating program: ', error);
      });
  }

  const handleClickActivity = (selectedActivity) => {
    if (selectedActivity && selectedActivity._id) {
      const section = program.sections.find(section => section.activities.some(activity => activity._id === selectedActivity._id));
      if (section) {
        navigate(`/program-templates/${program._id}/section/${section._id}/activities/${selectedActivity._id}`, {
          state: { selectedActivity },
        });
      } else {
        console.log('Error: section for activity is not found');
      }
    } else {
      console.log('Error: selectedActivity is undefined');
    }
  }

  const handelNewActivityButtonClick = (sectionID) => {
    console.log('handelNewActivityButtonClick on TrainerProgramDetailPage');
    setNewActivity({
      title: '',
      workouts: [],
      sectionID: sectionID
    })
    setIsNewActivityModalOpen(true);

  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newSection = { title: sectionTitle, activities: [] };
    program.sections.push(newSection);

    addNewSectionInProgramTemplate(program._id, newSection)
      .then((response) => {
        console.log('Program updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error adding new section to program: ', error);
      });

    setIsModalOpen(false);
  };

  const handleCloseModal = () => {
    setSectionTitle('');
    setIsModalOpen(false);
  }

  const handleNewActivitySubmit = async (e) => {
    e.preventDefault();
    const updatedSection = program.sections.find(section => section._id === newActivity.sectionID);
    updatedSection.activities.push(newActivity);

    const newActivityToSendToServer = {
      activities: updatedSection.activities
    }

    updateSectionInProgramTemplate(program._id, newActivity.sectionID, newActivityToSendToServer)
      .then((response) => {
        console.log('Program section updated successfully');
        setProgram(response.data);
      })
      .catch((error) => {
        console.error('Error updating new section to program: ', error);
      });

    setNewActivity('');
    setIsNewActivityModalOpen(false);
  }

  const handleNewActivityChange = (e) => {
    setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
  }

  const handleCloseNewActivityModal = () => {
    setNewActivity('');
    setIsNewActivityModalOpen(false);
  }

  const newSectionModal = () => {
    return (
      <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => handleCloseModal()}>
        <div className="form-box">
          <h1>Create an Section</h1>
          <form onSubmit={handleSubmit}>
            <label>
              Title:
              <input
                type="text"
                name="title"
                placeholder="Week 1"
                value={sectionTitle}
                onChange={handleChange}
              />
            </label>
            {message && <p>{message}</p>}
            <Button primary type="submit">Create Section</Button>
          </form>
          <div className="grid-container">
            <Button secondary onClick={() => handleCloseModal()}>Cancel</Button>
          </div>
        </div>
      </Modal>
    );
  }

  const newActivityModal = () => {
    return (
      <Modal className="ReactModal__Overlay" isOpen={isNewActivityModalOpen} onRequestClose={() => handleCloseModal()}>
        <div className="form-box">
          <h1>Create an Activity</h1>
          <form onSubmit={handleNewActivitySubmit}>
            <label>
              Title:
              <input
                type="text"
                name="title"
                placeholder="Day 1 or Monday Workouts"
                value={newActivity.title}
                onChange={handleNewActivityChange}
              />
            </label>
            {message && <p>{message}</p>}
            <Button primary type="submit">Create Activity</Button>
          </form>
          <div className="grid-container">
            <Button secondary onClick={() => handleCloseNewActivityModal()}>Cancel</Button>
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <div>
      <div className="header">
        <div className="header-title">{program.title}</div>
        <div><Button primary onClick={() => handleAddSectionTapped()}>Add Section</Button></div>
      </div>
      <p className="program-description">{program.description}</p>
      <div className="section-boxes-wrapper">
        {program.sections.length > 0 ? (
          <SectionsList
            sections={program.sections}
            isTrainer={true}
            onEdit={handleSectionTitleEdit}
            onDuplicate={handleSectionDuplicate}
            onDeleteActivity={handleDeleteActivity}
            onDelete={handleSectionDelete}
            onActivityClick={handleClickActivity}
            onNewActivityClick={handelNewActivityButtonClick}
          />
        ) : "No Sections for Program"}
      </div>
      {newSectionModal()}
      {newActivityModal()}
    </div>
  )
};

export default TrainerProgramDetailPage;