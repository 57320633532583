import React, { createContext, useState, useContext } from 'react';

const ProgramContext = createContext();

export function ProgramProvider({ children }) {
  const [program, setProgram] = useState(null);
  const [currentActivity, setCurrentActivity] = useState(null);
  const [workoutIndex, setSelectedWorkout] = useState(null);

  return (
    <ProgramContext.Provider value={{ program, setProgram, currentActivity, setCurrentActivity, workoutIndex, setSelectedWorkout }}>
      {children}
    </ProgramContext.Provider>
  );
}

export function useProgram() {
  return useContext(ProgramContext);
}
