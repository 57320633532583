import React from "react";
import ReactDOM from 'react-dom';
import Button from '../components/Button';
import { useEffect } from 'react';

function Modal({ onClose, onChange, onSubmit, children, actionBar }) {
    // useEffect(() => {
    //     document.body.classList.add('overflow-hidden');

    //     return () => {
    //         document.body.classList.remove('overflow-hidden');
    //     }
    // }, []);

    return ReactDOM.createPortal (
    //     <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => onClose()}>
    //     <div className="form-box">
    //       <h1>Create a workout</h1>
    //       <form onSubmit={onSubmit}>
    //         <label>
    //           <input
    //             type="text"
    //             name="title"
    //             placeholder="Workout Name"
    //             value={workoutToCreate.title}
    //             onChange={onChange}
    //           />
    //           <input
    //             type="text"
    //             name="muscleGroup"
    //             placeholder="Muscle Group"
    //             value={workoutToCreate.title}
    //             onChange={onChange}
    //           />
    //         </label>
    //         <VideoUpload />
    //         {/* {message && <p>{message}</p>} */}
    //         <Button primary type="submit">Create Workout</Button>
    //       </form>
    //       <div className="grid-container">
    //           <Button secondary  onClick={() => onClose()}>Cancel</Button>
    //       </div>
    //     </div>
    // </Modal>
    );
}

export default Modal;