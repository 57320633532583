import api from './api';

let token = null;

export const setAuthTokenForAPI = (newToken) => {
  token = newToken;
  api.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

export const removeAuthToken = () => {
  token = null;
  delete api.defaults.headers.common['Authorization'];
};

export const login = async (credentials) => {
  try {
    const response = await api.post('/login', credentials);
  
    if (response.status === 200) {
      const token = response.data.token;
  
      if (token) {
          console.log('Got token')
          // Save the token and redirect to another page
          setAuthTokenForAPI(token);
          return { success: true, error: null, token };
      } else {
          // Handle missing token error
          console.log(response)
          return { success: false, error: Error('Missing token') };
      }
    } else {
        // Handle error
        console.log('Error but not catch')
        return { success: false, error: Error('Login Failed:  token' + response.message) };
    }
  } catch (error) {
    return { success: false, error: Error('Login Failed: ' + error) };
  }
};

export const sendPasswordReset = async (email) => {
  try {
    const response = await api.post('/forgot-password', { email });
    if (response.status === 200) {
      return { success: true, error: null, statusCode: response.status };
    } else {
      return { success: false, error: null, statusCode: response.status };
    }
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    return { success: false, error: Error('Forgot password failed: ' + error), statusCode };
  }
}

export const resetPassword = async (token, newPassword) => {
  try {
    const response = await api.post('/reset-password', { token, newPassword });
    if (response.status === 200) {
      return { success: true, error: null, statusCode: response.status };
    } else {
      return { success: false, error: Error('Reset password failed: ' + response.message), statusCode: response.status };
    }
  } catch (error) {
    const statusCode = error.response ? error.response.status : null;
    return { 
      success: false, 
      error: Error('Reset password failed: ' + error),
      statusCode
    }
  };
}

export const logout = () => {
  removeAuthToken();
  // Additional logout logic like redirecting to the login page
};

export const getUserFromToken = async (token) => {
    const response = await api.get('/user', {
        headers: { Authorization: `Bearer ${token}` },
    });
    console.log(response)
    return response;
};

export const changePassword = async (password) => {
  const response = await api.put('/user', password);
  return response;
};

// 
// export const forgotPassword = (x) => api.post('/forgot-password', x);
// export const register = (x) => api.post('/register', x);
// export const tokenRefresh = (token) => api.post('token-refresh', token);