import React from 'react';
import className from 'classnames';
import './Button.css';

function Button({
  children,
  primary,
  secondary,
  success,
  warning,
  danger,
  outline,
  rounded,
  link,
  disabled,
  ...rest
}) {
  const classes = className(rest.className, 'my-button', {
    'primary-button': primary,
    'secondary-button': secondary,
    'success-button': success,
    'warning-button': warning,
    'danger-button': danger,
    'outline': outline,
    'rounded': rounded,
    'outline-primary': outline && primary,
    'outline-secondary': outline && secondary,
    'outline-success': outline && success,
    'outline-warning': outline && warning,
    'outline-danger': outline && danger,
    'link-button': link,
    'disabled-button': disabled,
  });

  return (
    <button {...rest} className={classes} disabled={disabled}>
      {children}
    </button>
  );
}

Button.propTypes = {
  checkVariationValue: ({ primary, secondary, success, warning, danger, link }) => {
    const count =
      Number(!!primary) +
      Number(!!secondary) +
      Number(!!success) +
      Number(!!warning) +
      Number(!!danger) +
      Number(!!link);

    if (count > 1) {
      return new Error('Only one of primary, secondary, success, warning, danger, link can be true');
    }
  },
};

export default Button;
