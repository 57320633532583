import React, { useState, useEffect } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import { getAllProgramTemplates } from '../shared/api/APIManager';
import Button from '../shared/components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';

function ProgramSelectionPage({ client, handleSelectedProgram }) {
    const location = useLocation();
    // const client = location.state.client;
    const [programs, setPrograms] = useState([]);
    const [selectedProgram, setSelectedProgram] = useState(null);

    useEffect(() => {
        getAllProgramTemplates()
        .then((response) => {
            console.log(response);
            setPrograms(response.data);
        })
        .catch((error) => {
            console.error('An error occurred while fetching the data: ', error);
        });
    }, []);

    const handelSavePgrogramSelection = () => {
        if (selectedProgram === 0) {
            console.error('Program is null');
        } else {
            console.log('Saved Program: ', selectedProgram);
            handleSelectedProgram(selectedProgram);
        }
    };

    const handleClickOnProgramBox = (program) => {
        console.log('Program Selected: ', program);
        setSelectedProgram(program);
    };

    const renderedPrograms = programs.map((program, index) => {
        const isSelected = selectedProgram && selectedProgram._id === program._id;
    
        return (
         <div key={program._id} className="box" onClick={() => handleClickOnProgramBox(program)}>
           <div className="box-content">
             <div className="box-label">{program.title}</div>
           </div>
           <div className="box-content">
             <div className="box-description">{program.description}</div>
           </div>
           {isSelected && (
                <div className="box-description">
                    <FontAwesomeIcon icon={faCircleCheck} />
                </div>
            )}
         </div>
        )
     });

     const saveProgramSelectionButton = selectedProgram ? (
        <Button primary onClick={() => handelSavePgrogramSelection()}>Create Program From Template</Button>
     ) : <Button disabled onClick={() => handelSavePgrogramSelection()}>Create Program From Template</Button>;


     // <div><Button primary onClick={() => handelSavePgrogramSelection()}>Create Program From Template</Button></div>
    return (
        <div>
            <div className="header">
                <div className="header-title">Saved Program Templates</div>
                <div>{saveProgramSelectionButton}</div>
            </div>
            <div className="boxes-wrapper">
                Select Program Template to create a new program for {client.firstName} {client.lastName}
                {renderedPrograms.length > 0 ? renderedPrograms : "No Saved Programs" }
            </div>
        </div>
    );
}

export default ProgramSelectionPage;