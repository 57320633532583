import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getProgram } from '../shared/api/APIManager';

function ClientNutrition() {
    return (
        <div>
            <div className="header">
                <div className="header-title">Client Nutrion</div>
            </div>
            <div className="boxes-wrapper">
                <div className="box">
                    <div className="box-title">Nutrition Plan</div>
                    <div className="box-content">
                        <div className="box-content-title">Coming Soon</div>
                        <div className="box-content-description"></div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ClientNutrition;