import React, { useState } from 'react';
import Button from '../components/Button';
import Modal from 'react-modal';
import { useAuth, UserRole } from "../context/AuthProvider";
import { MessageModal } from './MessageModal';
import './AuthenticationPage.css';

function AuthenticationPage({ onLoginSuccess }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const { login, forgotPassword } = useAuth();

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState('');

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const credentials = { email, password };
    const { success, error, userRole } = await login(credentials);

    if (success) {
      // Save the token and redirect to another page
      setMessage('Login successful!');

      if (userRole === UserRole.ADMIN) {
        window.location.replace('/clients');
      } else if (userRole === UserRole.TRAINER) {
        window.location.replace('/clients');
      } else if (userRole === UserRole.CLIENT) {
        window.location.replace('/program');
      }

    } else {
      // Handle missing token error
      console.log(error)
      setMessage(`Login failed: ${error}`);
    }
  };

  // Handle Forgot password button click
  const handleForgotPassword = async (e) => {
    e.preventDefault();

    if (email && email.length > 1) {
      const { success, error, statusCode } = await forgotPassword(email);
      if (success || statusCode === 418) {
        setMessage('Password reset email sent');
      } else if (statusCode === 429) {
        setMessage('Too many requests, try again later');
      } else {
        setMessage(`Password reset failed: ${error}`);
      }
    } else {
      setMessage('Please enter your email address');
    }
  }

  const handleRegister = () => {
    // Redirect to the registration page or open a registration modal
    setModalMessage('Sorry registration is not open yet');
    openModal();
  };

  const registrationModal = (
    <Modal className="ReactModal__Overlay" isOpen={modalIsOpen} onRequestClose={closeModal}>
      <div className="general-modal">
        <div className="login-box">
          Sorry this feature is not ready
          <button onClick={closeModal}>Close</button>
        </div>
      </div>

      <h2>Register</h2>
      <form>
        <input type="text" placeholder="Username" />
        <input type="email" placeholder="Email" />
        <input type="password" placeholder="Password" />
        <button type="submit">Register</button>
      </form>
      <button onClick={closeModal}>Close</button>
    </Modal>
  );

  const generalModal = (
    <MessageModal modalIsOpen={modalIsOpen} closeModal={closeModal} message={modalMessage} />
  );

  return (
    <div className="login-container">
      <div className="login-box">
        <img src={`${process.env.PUBLIC_URL}/ATF-Logo.jpg`} alt="ATF Logo" className="logo" />
        <h1>Login</h1>
        <form onSubmit={handleSubmit}>
          <label>Email:</label>
          <input type="email" value={email} onChange={handleEmailChange} autoComplete="username" />
          <label>Password:</label>
          <input type="password" value={password} onChange={handlePasswordChange} autoComplete="current-password" />
          <button className="primary" type="submit">Login</button>
        </form>
        {message && <p>{message}</p>}
        <Button link onClick={handleForgotPassword}>Forgot Password?</Button>
        <div className="register-section">
          <p>Don't have an account?</p>
          <button onClick={handleRegister}>Register</button>
        </div>
      </div>
      {generalModal}
    </div>

  );
}

export default AuthenticationPage;