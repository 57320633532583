import React, { useState, useEffect } from 'react';
import './Program.css';
import { useNavigate } from 'react-router-dom';
import { getClientProgram } from '../shared/api/APIManager';
import { useAuth } from '../shared/context/AuthProvider';
import jwtDecode from 'jwt-decode';
import SectionsList from '../shared/pages/New/SectionList';

function Program() {
    const { token } = useAuth();
    const navigate = useNavigate();
    const [program, setProgram] = useState({
        title: '',
        description: '',
        sections: [],
    });

    useEffect(() => {
        getClientProgram(jwtDecode(token).id)
            .then((response) => {
                setProgram(response.data);
                console.log('Program data: ', response.data);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the data: ', error);
            });
    }, [token]);

    const handleClickActivity = (selectedActivity, sectionID) => {
        if (selectedActivity && selectedActivity._id) {
            navigate(`/programs/${program._id}/section/${sectionID}/activities/${selectedActivity._id}`, {
                state: { selectedActivity, isTrainer: false },
            });
        } else {
            console.log('Error: selectedActivity is undefined');
        }
    }

    return (
        <div>
            <div className="header">
                <div className="header-title">{program.title}</div>
            </div>
            <div className="section-boxes-wrapper">
                {program.sections.length > 0 ? (
                    <SectionsList
                        sections={program.sections}
                        isTrainer={false}
                        onActivityClick={handleClickActivity}
                    />
                ) : "No Sections for Program"}
            </div>
        </div>
    );
}

export default Program;