import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
    getClientProgram,
    updateSectionInProgram,
    addNewSectionInProgram,
    updateProgram,
    deleteUserWorkout,
    duplicateSectionInProgram,
    deleteSectionInProgram,
    deleteActivityInProgramSection
} from '../shared/api/APIManager';
import SectionsList from '../shared/pages/New/SectionList';
import Button from '../shared/components/Button';
import Modal from 'react-modal';

function ClientProgram() {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isNewActivityModalOpen, setIsNewActivityModalOpen] = useState(false);
    const [message, setMessage] = useState('');
    const [program, setProgram] = useState({
        title: '',
        description: '',
        sections: [],
    });
    const [sectionTitle, setSectionTitle] = useState('');
    const [newActivity, setNewActivity] = useState({
        title: '',
        workouts: [],
    });

    const { id } = useParams();

    useEffect(() => {
        // Fetch the program by ID from your API
        getClientProgram(id)
            .then((response) => {
                setProgram(response.data);
                console.log('Program data: ', response.data);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the data: ', error);
            });
    }, []);

    const handleDeleteProgramTapped = () => {
    }

    const handleReplaceProgramTapped = () => {
    }

    const handleActivityProgramTapped = () => {
        setMessage('');
        setIsModalOpen(true);
    }

    const handleChange = (e) => {
        setSectionTitle(e.target.value);
    };

    const handleDeleteActivity = async (activity, sectionID) => {
        const section = program.sections.find((s) => s._id === sectionID);
        if (!section) {
            console.error('Section not found');
            return;
        }

        const activityToDelete = section.activities.find((a) => a._id === activity._id);
        if (!activityToDelete) {
            console.error('Activity not found');
            return;
        }

        // Delete all userworkouts for saved program template
        activityToDelete.workouts.forEach(async (workout) => {
            try {
                const response = await deleteUserWorkout(workout._id);
                if (response.status === 200) {
                    console.log('UserWorkouts for activity deleted successfully');
                } else {
                    console.error('Error deleting user workouts for activity');
                }
            } catch (error) {
                console.error('Error deleting user workouts for activity:', error);
            }
        });

        deleteActivityInProgramSection(program._id, section._id, activityToDelete._id)
            .then((response) => {
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error deleting activity from section to program: ', error);
            });
    }

    const handleSectionEdit = (sectionTitleUpdate, sectionID) => {
        const indexToUpdate = program.sections.findIndex((s) => s._id === sectionID);

        program.sections[indexToUpdate].title = sectionTitleUpdate;

        updateProgram(program._id, program)
            .then((response) => {
                console.log('Program updated successfully');
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error updating program: ', error);
            });
    }

    const handleAddSectionTapped = () => {
        setIsModalOpen(true);
    }

    const handleSectionDuplicate = (section) => {
        console.log('handleSectionDuplicate on TrainerProgramDetailPage');
        program.sections.push(section);

        //(programToUpdate, sectionID)
        duplicateSectionInProgram(program._id, section._id)
            .then((response) => {
                console.log('Program updated successfully');
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error updating program: ', error);
            });
    }

    const handleSectionDelete = (sectionToRemove) => {
        const newSections = program.sections.filter(section => section._id !== sectionToRemove._id);
        setProgram({ ...program, sections: newSections });
        let updatedProgram = { ...program, sections: newSections }

        updateProgram(updatedProgram._id, updatedProgram)
            .then((response) => {
                console.log('Program updated successfully');
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error updating program: ', error);
            });
    }

    const handleClickActivity = (selectedActivity, sectionID) => {
        if (selectedActivity && selectedActivity._id) {
            navigate(`/programs/${program._id}/section/${sectionID}/activities/${selectedActivity._id}`, {
                state: { selectedActivity, isTrainer: true },
            });
        } else {
            console.log('Error: selectedActivity is undefined');
        }
    }

    const handelNewActivityButtonClick = (sectionID) => {
        console.log('handelNewActivityButtonClick on TrainerProgramDetailPage');
        setNewActivity({
            title: '',
            workouts: [],
            sectionID: sectionID
        })
        setIsNewActivityModalOpen(true);

    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const newSection = { title: sectionTitle, activities: [] };
        program.sections.push(newSection);

        addNewSectionInProgram(program._id, newSection)
            .then((response) => {
                console.log('Program updated successfully');
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error adding new section to program: ', error);
            });

        setIsModalOpen(false);
    };

    const handleCloseModal = () => {
        setSectionTitle('');
        setIsModalOpen(false);
    }

    const handleNewActivitySubmit = async (e) => {
        e.preventDefault();
        const updatedSection = program.sections.find(section => section._id === newActivity.sectionID);
        updatedSection.activities.push(newActivity);

        const newActivityToSendToServer = {
            activities: updatedSection.activities
        }

        updateSectionInProgram(program._id, newActivity.sectionID, newActivityToSendToServer)
            .then((response) => {
                console.log('Program section updated successfully');
                setProgram(response.data);
            })
            .catch((error) => {
                console.error('Error updating new section to program: ', error);
            });

        setNewActivity('');
        setIsNewActivityModalOpen(false);
    }

    const handleNewActivityChange = (e) => {
        setNewActivity({ ...newActivity, [e.target.name]: e.target.value });
    }

    const handleCloseNewActivityModal = () => {
        setNewActivity('');
        setIsNewActivityModalOpen(false);
    }

    const newSectionModal = () => {
        return (
            <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => handleCloseModal()}>
                <div className="form-box">
                    <h1>Create an Section</h1>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Title:
                            <input
                                type="text"
                                name="title"
                                placeholder="Week 1"
                                value={sectionTitle}
                                onChange={handleChange}
                            />
                        </label>
                        {message && <p>{message}</p>}
                        <Button primary type="submit">Create Section</Button>
                    </form>
                    <div className="grid-container">
                        <Button secondary onClick={() => handleCloseModal()}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    }

    const newActivityModal = () => {
        return (
            <Modal className="ReactModal__Overlay" isOpen={isNewActivityModalOpen} onRequestClose={() => handleCloseModal()}>
                <div className="form-box">
                    <h1>Create an Activity</h1>
                    <form onSubmit={handleNewActivitySubmit}>
                        <label>
                            Title:
                            <input
                                type="text"
                                name="title"
                                placeholder="Day 1 or Monday Workouts"
                                value={newActivity.title}
                                onChange={handleNewActivityChange}
                            />
                        </label>
                        {message && <p>{message}</p>}
                        <Button primary type="submit">Create Activity</Button>
                    </form>
                    <div className="grid-container">
                        <Button secondary onClick={() => handleCloseNewActivityModal()}>Cancel</Button>
                    </div>
                </div>
            </Modal>
        );
    }

    const isTrainer = () => {
        return false
    }

    return (
        <div>
            <div className="header">
                <div className="header-title">Client Program: {program.title}</div>
                <div><Button primary onClick={() => handleAddSectionTapped()}>Add Section</Button></div>
            </div>
            <div className="section-boxes-wrapper">
                {program.sections.length > 0 ? (
                    <SectionsList
                        sections={program.sections}
                        isTrainer={true}
                        onEdit={handleSectionEdit}
                        onDuplicate={handleSectionDuplicate}
                        onDeleteActivity={handleDeleteActivity}
                        onDelete={handleSectionDelete}
                        onActivityClick={handleClickActivity}
                        onNewActivityClick={handelNewActivityButtonClick}
                    />
                ) : "No Sections for Program"}
            </div>
            {newSectionModal()}
            {newActivityModal()}
        </div>
    );
}

export default ClientProgram;