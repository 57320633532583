import api from "./api";
// import uploadVideoAPI from "./uploadVideoAPI";

// import axios from 'axios';
// import { useAuth } from '../context/AuthProvider';

// const apiBaseURL = 'http://localhost:4000/api'

// const api = axios.create({
//   baseURL: apiBaseURL,
 
// });

// const openAPI = axios.create({
//   baseURL: apiBaseURL
// })

// function API() {
//   const { token } = useAuth();
//   const api = axios.create({
//     baseURL: 'https://api.example.com',
//     headers: {
//       'Authorization': `Bearer ${token}`
//     }
//   });
//   return api;
// }

// function RetrieveToken() {
//   const { token } = useAuth();

// }

// Authentication Calls - Moved to authService.js
// export const login = (credentials) => api.post('/login', credentials);
// export const forgotPassword = (x) => api.post('/forgot-password', x);
// export const register = (x) => api.post('/register', x);
// export const tokenRefresh = (token) => api.post('token-refresh', token);

// Client calls
export const addClient = (client) => api.post('/clients', client);

export const getAllTrainerClients = (trainerId) => api.get('/clients', {
    params: {
      trainer: trainerId
    }
});
export const getClient = (id) => api.get(`/clients/${id}`);

export const getAllTrainerClientsFromToken = (trainerId) => api.get('/my-clients');

export const getClientProgram = (id) => api.get(`/programs/client/${id}`);


// ================================================
// Template Calls
// ================================================

// Template Program Calls
export const createProgramTemplate = (program) => api.post('/program-templates', program);
export const getAllProgramTemplates = () => api.get('/program-templates');
export const getProgramTemplate = (id) => api.get(`/program-templates/${id}`);
export const updateProgramTemplate = (id, updatedProgram) => api.put(`/program-templates/${id}`, updatedProgram);
export const addNewSectionInProgramTemplate = (programId, newSection) => api.post(`/program-templates/${programId}/section`, newSection);
export const duplicateSectionInProgramTemplate = (programId, sectionID) => api.post(`/program-templates/${programId}/section/${sectionID}`);
export const updateSectionInProgramTemplate = (programId, sectionID, section) => api.put(`/program-templates/${programId}/section/${sectionID}`, section);
export const deleteSectionInProgramTemplate = (programId, sectionID) => api.delete(`/program-templates/${programId}/section/${sectionID}`);
export const updateActivityForProgramTemplate = (id, sectionID, activityID, updatedActivityForSection) => api.put(`/program-templates/${id}/section/${sectionID}/activities/${activityID}`, updatedActivityForSection);
export const deleteActivityInProgramTemplateSection = (id, sectionID, activityID) => api.delete(`/program-templates/${id}/section/${sectionID}/activities/${activityID}`);
export const updateTemplateWorkout = (id, updatedWorkout) => api.put(`/template-workouts/${id}`, updatedWorkout);
export const deleteProgramTemplate = (id) => api.delete(`/program-templates/${id}`);

// Template Activity Calls
export const createTemplateActivityAndAddToProgramTemplate = (id, activity) => api.post(`/program-templates/${id}/activities`, activity);
export const createTemplateActivityAndAddToProgram = (id, activity) => api.post(`/programs/${id}/activities`, activity);
export const getAllTemplateActivities = () => api.get('/template-activites');
export const getTemplateActivity = (id) => api.get(`/template-activites/${id}`);
export const updateTemplateActivity = (id, updatedActivity) => api.put(`/template-activities/${id}`, updatedActivity);
export const deleteTemplateActivity = (id) => api.delete(`/template-activities/${id}`);

// Template User Workout Calls
export const createTemplateUserWorkout = (userWorkout) => api.post(`/template-user-workouts`, userWorkout);
export const getTemplateUserWorkoutForActivity = (id) => api.get(`/template-user-workouts/${id}`);
export const deleteTemplateUserWorkout = (id) => api.delete(`/template-user-workouts/${id}`);
export const addTemplateSetToUserWorkout = (id, set) => api.put(`/template-user-workouts/${id}/sets`, set);
export const updateSetInTemplateUserWorkout = (id, setID, updatedSet) => api.put(`/template-user-workouts/${id}/sets/${setID}`, updatedSet);
export const deleteSetInTemplateUserWorkout = (id, setID) => api.delete(`/template-user-workouts/${id}/sets/${setID}`);

// ================================================
// Regular Calls
// ================================================

// Program calls
export const createProgram = (program) => api.post('/programs', program);
export const createProgramFromProgramTemplate = (programTemplateId, clientId) => api.post(`/programs/from-template/${programTemplateId}/client/${clientId}`);
export const getAllPrograms = () => api.get('/programs');
export const getProgram = (id) => api.get(`/programs/${id}`);
export const updateProgram = (id, updatedProgram) => api.put(`/programs/${id}`, updatedProgram);
export const updateActivityForProgram = (id, activityID, updatedProgramDetails) => api.put(`/programs/${id}/activities/${activityID}`, updatedProgramDetails);
export const deleteProgram = (id) => api.delete(`/programs/${id}`);

export const addNewSectionInProgram = (programId, newSection) => api.post(`/programs/${programId}/section`, newSection);
export const duplicateSectionInProgram = (programId, sectionID) => api.post(`/programs/${programId}/section/${sectionID}`);
export const updateSectionInProgram = (programId, sectionID, section) => api.put(`/programs/${programId}/section/${sectionID}`, section);
export const deleteSectionInProgram = (programId, sectionID) => api.delete(`/programs/${programId}/section/${sectionID}`);
export const deleteActivityInProgramSection = (id, sectionID, activityID) => api.delete(`/programs/${id}/section/${sectionID}/activities/${activityID}`);

// Activity Calls
export const createActivityAndAddToProgramTemplate = (id, activity) => api.post(`/program-templates/${id}/activities`, activity);
export const createActivityAndAddToProgram = (id, activity) => api.post(`/programs/${id}/activities`, activity);
export const getAllActivities = () => api.get('/activites');
export const getActivity = (id) => api.get(`/activites/${id}`);
export const updateActivity = (id, updatedActivity) => api.put(`/activities/${id}`, updatedActivity);
export const deleteActivity = (id) => api.delete(`/activities/${id}`);

// Workout Calls
export const createWorkoutAndAddToActivity = (id, workout) => api.post(`/activites/${id}/workouts`, workout);
// export const createWorkout = (workout) => uploadVideoAPI.post(`/workouts`, workout);
export const getAllWorkouts = () => api.get('/workouts');
export const getAllWorkoutsForActivity = (id) => api.get(`/activities/${id}/workouts`);
export const getAllWorkoutsForActivityInProgram = (programID, activityID) => api.get(`/programs/${programID}/activities/${activityID}/workouts`);
export const getWorkout = (id) => api.get(`/workouts/${id}`);
export const updateWorkout = (id, updatedWorkout) => api.put(`/workouts/${id}`, updatedWorkout);
export const deleteWorkout = (id) => api.delete(`/workouts/${id}`);

// User Workout Calls
export const createUserWorkout = (userWorkout) => api.post(`/user-workouts`, userWorkout);
export const getUserWorkoutForActivity = (id) => api.get(`/user-workouts/activities/${id}`);
export const updateUserWorkout = (id, updatedWorkout) => api.put(`/user-workouts/${id}`, updatedWorkout);
export const addSetToUserWorkout = (id, set) => api.put(`/user-workouts/${id}/sets`, set);
export const updateSetInUserWorkout = (id, setID, updatedSet) => api.put(`/user-workouts/${id}/sets/${setID}`, updatedSet);
export const deleteSetInUserWorkout = (id, setID) => api.delete(`/user-workouts/${id}/sets/${setID}`);
export const deleteUserWorkout = (id) => api.delete(`/user-workouts/${id}`);