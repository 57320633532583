import AuthenticationPage from "./AuthenticationPage";
import AccountPage from "./AccountPage";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function AuthenticationPageOrAccountPage() {
  const { token } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (token !== null) {
      navigate('/account');
    }
  }, [navigate, token]);

  if (token !== null) {
    return <AccountPage />;
  } else {
    return <AuthenticationPage />;
  }
}

export default AuthenticationPageOrAccountPage;