import React from "react";
import Button from "../../components/Button";

function ProgramItem({ activity, sectionID, handleOnClick, isClient, handleDelete }) {
    const showDeleteButton = () => {
        if (!isClient) {
            return (
                <div className="box-content" onClick={(e) => e.stopPropagation()}>
                    <Button danger onClick={() => handleDelete(activity)}>Delete {activity.title}</Button>
                </div>
            )
        } else {
            return null;
        }
    }

    return (
        <div className="box" onClick={(e) => {
            e.stopPropagation();
            handleOnClick(activity, sectionID);
        }}>
            <div className="box-content">
                <div className="box-label">{activity.title}</div>
            </div>
            <div className="box-content">
                <div className="box-description">Workouts: {activity.workouts.length}</div>
            </div>
            {showDeleteButton()}
        </div>
    )
}

export default ProgramItem;