import React, { useState, useEffect } from 'react';
import { createProgramTemplate, getAllProgramTemplates } from '../../shared/api/APIManager';
import Modal from 'react-modal'; // Or your custom modal component
// import './ProgramList.css';
import './CreateEditForm.css';
import Button from '../../shared/components/Button';
import { useNavigate } from 'react-router-dom';

function ProgramsPage() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [programs, setPrograms] = useState([]);
  const [program, setProgram] = useState({
    title: '',
    description: '',
  });
  const [message, setMessage] = useState('');
  const [showForm, setShowForm] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch all programs from your API
    getAllProgramTemplates()
      .then((response) => {
        console.log(response);
        setPrograms(response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching the data: ', error);
      });
  }, []);

  const handleCreateProgramTapped = () => {
    setProgram({
      title: '',
      description: '',
    });
    setMessage('');
    setIsModalOpen(!isModalOpen);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProgram({
      ...program,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (program.title.length < 1) {
        setMessage('Program Name Required');
        return
      } else if (program.description.length < 1) {
        setMessage('Program description required');
        return
      }

      const response = await createProgramTemplate(program);
      setMessage('Program created successfully!');
      setPrograms([...programs, response.data]); // Update the program list
      setIsModalOpen(false)
    } catch (error) {
      console.error('Error creating program:', error);
      setMessage('Error creating program.');
    }
  };

  const handleClickOnProgram = (programItem) => {
    navigate(`/program-templates/${programItem._id}`);
  }  

  const renderedPrograms = programs.map((program, index) => {
   return (
    <div key={program._id} className="box" onClick={() => handleClickOnProgram(program)}>
      <div className="box-content">
        <div className="box-label">{program.title}</div>
      </div>
      <div className="box-content">
        <div className="box-description">{program.description}</div>
      </div>
    </div>
   )
});
  
  return (
    <div>
      <div className="header">
          <div className="header-title">Saved Program Templates</div>
          <div><Button primary onClick={() => handleCreateProgramTapped()}>Create Program</Button></div>
      </div>
      <div className="boxes-wrapper">
        {renderedPrograms.length > 0 ? renderedPrograms : "No Programs" }
      </div>
      <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => setIsModalOpen(false)}>
          <div className="form-box">
            <h1>Create a Program</h1>
            <form onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="title"
                  value={program.title}
                  onChange={handleChange}
                />
              </label>
              <label>
                Description:
                <input
                  type="text"
                  name="description"
                  value={program.description}
                  onChange={handleChange}
                />
              </label>
              {message && <p>{message}</p>}
              <Button primary type="submit">Create Program</Button>
            </form>
            <div className="grid-container">
                <Button secondary onClick={() => setIsModalOpen(false)}>Cancel</Button>
            </div>
          </div>
          
      </Modal>
    </div>
  );
};

export default ProgramsPage;