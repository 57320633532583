import React from 'react';
import './WorkoutDetail.css';
import { useProgram } from '../context/ProgramContext';
import { Workouts } from './DemoData';

function WorkoutDetail({ xx }) {
    // const { workout } = useNavigate();
    const { workoutIndex } = useProgram();

    const workout = Workouts[workoutIndex];
    console.log(workout)
    if (!workout) {
        return <div>Workout not found</div>;
    } else {
        return (
            <div>
                <h1 className="App title text-lg font-bold">{workout.name}</h1>
                <div className="header">
                    <div className="header-column">Reps</div>
                    <div className="header-column">Weight</div>
                </div>
                <div className="boxes-wrapper">
                    {workout.sets.map((set, index) => (
                        <div key={index} className="box">
                        <div className="box-label">Set {index + 1}</div>
                        <div className="box-content">
                            <div className="box-column">
                            <p>{set.repGoal} Reps</p>
                            <input type="number" placeholder="Actual Reps" />
                            </div>
                            <div className="box-column">
                            <p>{set.setWeight} lbs</p>
                            </div>
                        </div>
                        <div className="box-check">
                            <input type="checkbox" id={`complete${index}`} checked={set.completed} />
                            <label htmlFor={`complete${index}`}>Complete</label>
                        </div>
                        </div>
                    ))}
                </div>
                <div className="video-wrapper">
                <iframe 
                    width="560" 
                    height="315" 
                    src="https://player.vimeo.com/video/744276080" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen>
                </iframe>
                </div>
            </div>
        )
    }
}

export default WorkoutDetail;