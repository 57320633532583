import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getAllWorkouts } from '../../api/APIManager';
import Button from '../../components/Button';
import Modal from 'react-modal';
import './WorkoutsList.css';
import './Workout.css'
import Workout, { WorkoutViewType } from './Workout';
import api from '../../api/api';

function WorkoutsList({ onSelectedWorkout }) {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [message, setMessage] = useState('');
    const [workouts, setWorkouts] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [displayedItemsCount, setDisplayedItemsCount] = useState(10); 
    const [selectedFilter, setSelectedFilter] = useState('All');

    useEffect(() => {
        getAllWorkouts()
            .then((response) => {
                const filteredWorkouts = response.data.filter(workout => !workout.muscleGroup.toLowerCase().includes("none"));
                setWorkouts(filteredWorkouts);
            })
            .catch((error) => {
                console.error('An error occurred while fetching the data: ', error);
            });

            setDisplayedItemsCount(10);
            setCurrentPage(1);  
    }, [selectedFilter]);

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
        setCurrentPage(1); // Reset to the first page on search
        setCurrentPage(1);
        if (e.target.value === '') {
            setDisplayedItemsCount(itemsPerPage);
        } else {
            setDisplayedItemsCount(filteredWorkouts.length);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    }

    const handleWorkoutClick = (workout) => {
        console.log(`WorkoutList.js: ${workout}`);
        onSelectedWorkout(workout)
    }

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const filteredWorkouts = workouts.filter(workout => 
        (selectedFilter === 'All' || workout.muscleGroup === selectedFilter) &&
               workout.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
    
    const totalPages = Math.ceil(filteredWorkouts.length / itemsPerPage);

    const currentWorkouts = filteredWorkouts.slice(0, displayedItemsCount);

    const loadMoreItems = () => {
        setDisplayedItemsCount(prevCount => prevCount + 10); // Load 10 more items
        setCurrentPage(prevPage => prevPage + 1);
    };

    const renderedWorkoutItems = currentWorkouts.map((workout, index) => (
        <Workout key={workout._id} viewType={WorkoutViewType.workoutList} workout={workout} onUserClicked={() => handleWorkoutClick(workout)} />
    ));
    
    return (
        <div>
            <div className="header">
                <div className="header-title">Workouts: {displayedItemsCount} / {filteredWorkouts.length}</div>
                <div className="header-title">Page {currentPage} / {totalPages}</div>
                <div>
                    <select value={selectedFilter} onChange={(e) => setSelectedFilter(e.target.value)}>
                        <option value="All">All</option>
                        <option value="Arms">Arms</option>
                        <option value="Back Movements">Back Movements</option>
                        <option value="Chest Folder">Chest</option>
                        <option value="Glutes">Glutes</option>
                        <option value="Hamstrings">Hamstrings</option>
                        <option value="Leg Movements">Leg Movements</option>
                        <option value="new workouts">New Workouts</option>
                        <option value="Shoulders">Shoulders</option>
                    </select>
                </div>
                <div className="spacer"></div>
                <div>
                    <input 
                        type="text" 
                        placeholder="Search workouts..." 
                        value={searchTerm} 
                        onChange={(e) => handleSearchChange(e)} 
                    />
                </div>
            </div>
            <div className="boxes-wrapper">
                {renderedWorkoutItems.length > 0 ? renderedWorkoutItems : "No workouts found"}
                {displayedItemsCount < workouts.length && (
                    <div>
                    <div>Displayed workouts {displayedItemsCount} / {filteredWorkouts.length}</div>
                    <div>Page {currentPage} / {totalPages}</div>
                    <Button primary onClick={() => loadMoreItems()}>Load More</Button>
                    </div>
                )}
            </div>
        </div>
    )
}

export default WorkoutsList;