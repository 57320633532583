import React, { createContext, useContext, useState, useEffect } from 'react';
import { 
  login as loginService, 
  setAuthTokenForAPI, 
  sendPasswordReset as sendPasswordResetService, 
  resetPassword as resetPasswordService  } from '../api/authService';
import jwtDecode from 'jwt-decode';

const AuthContext = createContext();

export const UserRole = {
  ADMIN: 'admin',
  TRAINER: 'trainer',
  CLIENT: 'client' 
};

export function AuthProvider({ children }) {
  const [token, setToken] = useState(localStorage.getItem('token'));

  const login = async (credentials) => {
    const { success, error, token: newToken } = await loginService(credentials);
    if (success) {
      const decodedToken = jwtDecode(newToken);
      const userRole = decodedToken.role;
      setToken(newToken);
      setAuthTokenForAPI(newToken);
      localStorage.setItem('token', newToken);
      return { success: true, error: null, userRole };
    } else {
      // Handle the error here
      console.error(error);
      return { success: false, error: error };
    }
  };

  const logout = () => {
    localStorage.removeItem('token');
    setToken(null);
    window.location.replace('/login');
  };

  const forgotPassword = async (email) => {
    const { success, error, statusCode} = await sendPasswordResetService(email);
    if (success) {
      return { success: true, error: null, statusCode};
    } else {
      // Handle the error here
      console.error(error);
      return { success: false, error: error, statusCode };
    }
  }

  const resetPassword = async (token, newPassword) => {
    const { success, error, statusCode } = await resetPasswordService(token, newPassword);
    if (success) {
      return { success: true, error: null, statusCode};
    } else {
      // Handle the error here
      console.error(error);
      return { success: false, error: error, statusCode: statusCode };
    }
  }

  return (
    <AuthContext.Provider value={{ token, login, logout, forgotPassword, resetPassword }}>
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}