const DemoProgramWithActivites = [
    {
        "id": 1,
        "title": "Bryan's Program",
        "trainerID": 1,
        "clientID": 122,
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "activities": [ 
            {
                "id": 1,
                "period": "Day 1",
                "title": "Hamstrings",
                "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
                "clientID": 434535,
                "workouts": [ 1, 2, 3 ]
            }
        ]
    }
]


const DemoData = [
    {
        "id": 1,
        "period": "Day 1",
        "title": "Hamstrings",
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "workouts": [
            {
                "id": 1,
                "name": "Smith Machine RDLs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 2,
                "name": "Seated Leg Curls",
                "muscleGroup": "Hamstrings",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "id": 3,
                "name": "Cable Pull throughs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 4,
                "name": "Ham glutes hyperextension",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 5,
                "name": "Laydown Leg Curls",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            }
        ],
    },
    {
        "id": 2,
        "period": "Day 2",
        "title": "Shoulders/Triceps",
        "description": "45 min post workout cardio- Stairmaster level 8-12",
        "workouts": [
            {
                "id": 1,
                "name": "Smith Machine RDLs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 2,
                "name": "Seated Leg Curls",
                "muscleGroup": "Hamstrings",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "id": 3,
                "name": "Cable Pull throughs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 4,
                "name": "Ham glutes hyperextension",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 5,
                "name": "Laydown Leg Curls",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            }
        ],
    },
    {
        "id": 3,
        "period": "Day 3",
        "title": "Compound Leg Day",
        "description": "30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
        "workouts": [
            {
                "id": 1,
                "name": "Smith Machine RDLs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 2,
                "name": "Seated Leg Curls",
                "muscleGroup": "Hamstrings",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "id": 3,
                "name": "Cable Pull throughs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 4,
                "name": "Ham glutes hyperextension",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 5,
                "name": "Laydown Leg Curls",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            }
        ],
    },
    {
        "id": 4,
        "period": "Day 4",
        "title": "Back and Biceps",
        "description": "45 min post workout cardio- Stairmaster level 8-12",
        "workouts": [
            {
                "id": 1,
                "name": "Smith Machine RDLs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 2,
                "name": "Seated Leg Curls",
                "muscleGroup": "Hamstrings",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "id": 3,
                "name": "Cable Pull throughs",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 4,
                "name": "Ham glutes hyperextension",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "id": 5,
                "name": "Laydown Leg Curls",
                "muscleGroup": "muscleGroup",
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            }
        ],
    }
];

const Workouts = [
    {
        "id": 1,
        "name": "Smith Machine RDLs",
        "muscleGroup": "muscleGroup",
        "sets": [
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            }
        ]
    },
    {
        "id": 2,
        "name": "Seated Leg Curls",
        "muscleGroup": "Hamstrings",
        "sets": [
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            }
        ]
    },
    {
        "id": 3,
        "name": "Cable Pull throughs",
        "muscleGroup": "muscleGroup",
        "sets": [
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            }
        ]
    },
    {
        "id": 4,
        "name": "Ham glutes hyperextension",
        "muscleGroup": "muscleGroup",
        "sets": [
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            }
        ]
    },
    {
        "id": 5,
        "name": "Laydown Leg Curls",
        "muscleGroup": "muscleGroup",
        "sets": [
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": true
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            },
            {
                "setWeight": 35,
                "actualReps": 10,
                "repGoal": 15,
                "completed": false
            }
        ]
    }
];

const DemoItems = [
    "Day 1: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 2: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 3: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 4: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 5: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 6 Conditioning",
    "Day 7: Full rest day",
    "Day 8: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 9: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 10: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 11: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 12: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 13: Conditioning",
    "Day 14: Full rest day",
    "Day 15: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 16: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 17: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 18: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 19: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 20: Conditioning",
    "Day 21: Full rest day",
    "Day 22: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 23: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 24: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 25: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 26: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 27: Conditioning",
    "Day 28: Full rest day",
    "Day 29: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 30: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 31: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 32: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 33: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 34: Conditioning",
    "Day 35: Full rest day",
    "Day 36: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 37: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 38: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 39: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 40: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 41: Conditioning",
    "Day 42: Full rest day",
    "Day 43: Hamstrings: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 44: Shoulders/Triceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 45: Compound Leg Day: 30min post workout cardio- Treadmill 10.0 incline 3.5 speed",
    "Day 46: Back and Biceps: 45 min post workout cardio- Stairmaster level 8-12",
    "Day 47: Glutes: 30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
    "Day 48: Conditioning",
    "Day 49: Full rest day",
];

const DataIdea = [
    {
        "id": 1,
        "period": "Day 1",
        "title": "Hamstrings",
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "clientID": 434535,
        "workouts": [
            {
                "id": 1,
                "name": "Smith Machine RDLs",
                "muscleGroup": "muscleGroup",
                "numberOfSets": 6,
                "minReps": 10,
                "maxReps": 15
            },
            {
                "id": 2,
                "name": "Seated Leg Curls",
                "muscleGroup": "Hamstrings",
                "numberOfSets": 4,
                "minReps": 8,
                "maxReps": 12
            }
        ],
    },
];


const DataPrograms = [
    {
        "id": 1,
        "title": "Bryan's Program",
        "trainerID": 1,
        "clientID": 122,
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "activities": [ 1, 2, 3],
    },
];

const DataActivities = [
    {
        "id": 1,
        "period": "Day 1",
        "title": "Hamstrings",
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "programID": 1,
        "workouts": [22, 34, 55]
    },
];

const DataWorkouts = [
    {
        "id": 22,
        "name": "Smith Machine RDLs",
        "muscleGroup": "muscleGroup",
        "setWeight": 35,
        "actualReps": 10,
        "repGoal": 15,
        "completed": true
    },
    {
        "id": 34,
        "name": "Seated Leg Curls",
        "muscleGroup": "Hamstrings",
        "setWeight": -1,
        "actualReps": -1,
        "repGoal": 20,
        "completed": false
    },
    {
        "id": 55,
        "name": "Cable Pull throughs",
        "muscleGroup": "muscleGroup",
        "setWeight": -1,
        "actualReps": -1,
        "repGoal": 12,
        "completed": false
    },
];

export { DemoData, DemoItems, Workouts }


/*
Programs

[
    {
        "id": 1,
        "title": "Bryan's Program",
        "trainerID": 1,
        "clientID": 122,
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "activities": [ 
                {
                    "id": 1,
                    "period": "Day 1",
                    "title": "Hamstrings",
                    "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
                    "clientID": 434535,
                    "workouts": [ 
                        {
                            "id": 22,
                            "name": "Smith Machine RDLs",
                            "muscleGroup": "muscleGroup",
                            "setWeight": 35,
                            "actualReps": 10,
                            "repGoal": 15,
                            "completed": true
                        },
                        {
                            "id": 34,
                            "name": "Seated Leg Curls",
                            "muscleGroup": "Hamstrings",
                            "setWeight": -1,
                            "actualReps": -1,
                            "repGoal": 20,
                            "completed": false
                        },
                        {
                            "id": 55,
                            "name": "Cable Pull throughs",
                            "muscleGroup": "muscleGroup",
                            "setWeight": -1,
                            "actualReps": -1,
                            "repGoal": 12,
                            "completed": false
                        },
                    ]
                }
            ],
    },
    {
        "id": 1,
        "title": "Bryan's Program",
        "trainerID": 1,
        "clientID": 122,
        "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
        "activities": [ 
                {
                    "id": 1,
                    "period": "Day 1",
                    "title": "Hamstrings",
                    "description": "30 mins post workout cardio- treadmill 12.0 incline 3.0 speed",
                    "clientID": 434535,
                    "workouts": [ 
                        {
                            "id": 22,
                            "name": "Smith Machine RDLs",
                            "muscleGroup": "muscleGroup",
                            "setWeight": 35,
                            "actualReps": 10,
                            "repGoal": 15,
                            "completed": true
                        },
                        {
                            "id": 34,
                            "name": "Seated Leg Curls",
                            "muscleGroup": "Hamstrings",
                            "setWeight": -1,
                            "actualReps": -1,
                            "repGoal": 20,
                            "completed": false
                        },
                        {
                            "id": 55,
                            "name": "Cable Pull throughs",
                            "muscleGroup": "muscleGroup",
                            "setWeight": -1,
                            "actualReps": -1,
                            "repGoal": 12,
                            "completed": false
                        },
                    ]
                }
            ],
    }

]
*/