import React from 'react';
// import useNavigation from '../hooks/use-navigation';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

function MyLink({ to, children, className, activeClassName }) {
    const { navigate, currentPath } = useNavigate();

    const classes = classNames(
        'text-blue-500', 
        className,
        currentPath === to && activeClassName
    );

    const handleClick = (event) => {
        if (event.metaKey || event.ctrlKey) {
            return 
        }

        navigate(to);
    };

    return <a className={classes} href={to} onClick={handleClick}>{children}</a>
}

export default MyLink;