import React, { useState } from 'react';
import './ResetPasswordPage.css';
import { useAuth } from "../context/AuthProvider";

function ResetPasswordPage() {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
    const { resetPassword } = useAuth();

    const handleResetPassword = async () => {
        if (password !== '' && confirmPassword !== '' && password === confirmPassword) {
            try {
                const params = new URLSearchParams(window.location.search);
                const token = params.get('token');

                const {success, statusCode } = await resetPassword(token, password);
                if (success) {
                    setMessage('Password has been reset.');
                    window.location.href = '/login';
                } else if (statusCode === 403) {
                    setMessage('The token is invalid or expired.');
                } else if (statusCode === 404) {
                    setMessage('Sorry password reset link is invalid or expired');
                } else if (statusCode === 429) {
                    setMessage('Too many reset attempts. Please try again later');
                } else {
                    setMessage('An error occurred. Please try again later');
                }
            } catch (error) {
                setMessage('An error occurred.');
            }
        } else {
            setMessage('Passwords do not match.');
        }
    };

    return (
        <div className="reset-password-container">
            <div className="reset-password-box">
                <h1 className="reset-password-title">Reset Password</h1>
                <input
                    className="input-field"
                    type="password"
                    placeholder="New Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                />
                <input
                    className="input-field"
                    type="password"
                    placeholder="Confirm New Password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                />
                <button className="button" onClick={() => handleResetPassword()}>Reset Password</button>
                {message && <p>{message}</p>}
            </div>
        </div>
    );
};

export default ResetPasswordPage;