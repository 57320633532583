// Display UI elements for uploading a video using react
import React, { useRef, useState } from 'react';

function VideoUpload({ onVideoFileChange }) {
    const [fileURL, setFileURL] = useState(null);

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        setFileURL(URL.createObjectURL(selectedFile));
        onVideoFileChange(selectedFile);
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const selectedFile = event.dataTransfer.files[0];
        setFileURL(URL.createObjectURL(selectedFile));
        onVideoFileChange(selectedFile);
    };

    return (
        <div
            style={{ border: '2px dashed #aaa', padding: '20px' }}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
        >
            <input
                type="file"
                accept="video/*"
                onChange={handleFileChange}
            />
            <p>{fileURL ? 'Preview:' : 'Drag and drop a video file here or click to select.'}</p>
            {fileURL && (
                <video controls>
                    <source src={fileURL} type="video/mp4" /> {/* Adjust the type if necessary */}
                    Your browser does not support the video tag.
                </video>
            )}
        </div>
    );
}

export default VideoUpload;