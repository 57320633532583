import React, { useEffect, useState } from "react";

function Meal() {
    const [meal, setMeal] = useState(null);


    return (
        <div>
            Meal 1 
        </div>
    )
}

export default Meal;