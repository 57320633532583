import React from 'react';
import Modal from 'react-modal';
import './MessageModal.css';

export function MessageModal({ message, modalIsOpen, closeModal }) {
  return (
    <Modal className="ReactModal__Overlay" isOpen={modalIsOpen} onRequestClose={closeModal}>
      <div className="general-modal">
        <div className="modal-content">
          <p className="modal-message">{message}</p>
          <button className="modal-button" onClick={closeModal}>Close</button>
        </div>
      </div>
    </Modal>
  );
}