import React, { createContext, useContext, useState } from 'react';

const NavigationContext = createContext();

export function NavigationProvider({ children }) {
  const [navigation, setNavigation] = useState(/* Your initial state here */);
  const [activity, setCurrentActivity] = useState(null);

  return (
      <NavigationContext.Provider value={{ navigation, setCurrentActivity }}>
        {children}
      </NavigationContext.Provider>
  );
}

export function useNavigation() {
  return useContext(NavigationContext);
}