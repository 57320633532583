import React, { useState } from 'react';
import SectionItem from './SectionItem';

function SectionsList({ isTrainer, sections, onEdit, onDuplicate, onDelete, onDeleteActivity, onActivityClick, onNewActivityClick }) {
    const [expandedSections, setExpandedSections] = useState({});

    const toggleSectionExpanded = (sectionId) => {
        setExpandedSections(prevState => ({
            ...prevState,
            [sectionId]: !prevState[sectionId]
        }));
    };

    return (
        <div>
            {sections.map(section => (
                <SectionItem
                    key={section._id}
                    section={section}
                    isExpanded={!!expandedSections[section._id]}
                    onExpandToggle={() => toggleSectionExpanded(section._id)}
                    isTrainer={isTrainer}
                    onEdit={onEdit}
                    onDuplicate={onDuplicate}
                    onDelete={onDelete}
                    onDeleteActivity={onDeleteActivity}
                    onClickOnActivity={onActivityClick}
                    onClickOnNewActivity={onNewActivityClick}
                />
            ))}
        </div>
    );
}

export default SectionsList;