import React, { useState, useEffect } from 'react';
import { addClient, getAllTrainerClientsFromToken } from '../shared/api/APIManager';
import { useParams, useNavigate } from 'react-router-dom';
import Modal from 'react-modal';
import MyLink from '../shared/components/MyLink';
import Button from '../shared/components/Button';

function ClientList() {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [clients, setClients] = useState([]);
  const [message, setMessage] = useState('');
  const [clientToCreate, setClientToCreate] = useState({
    firstName: '',
    lastName: '',
    email: '',
  });

  const handleNewClientChange = (e) => {
    const { name, value } = e.target;
    setClientToCreate({
      ...clientToCreate,
      [name]: value,
    })
  };

  const handleAddClientTapped = () => {
    setMessage('');
    setIsModalOpen(!isModalOpen);
  }

  const handleCloseModal = () => {
    setIsModalOpen(false);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setClientToCreate({
      firstName: '',
      lastName: '',
      email: ''
    });

    try {
      addClient(clientToCreate)
      .then((response) => {
        setClients(response.data);
      })
      setMessage('Client added successfully!');
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error adding client:', error);
      if (error.response) {
        setMessage('Error: ' + error.response.data.message);
      } else {
        setMessage('Error adding client');
      }
    }
  }

  const handleClickOnClient = (selectedClient) => {
    navigate(`/clients/${selectedClient._id}/dashboard`, {
      state: { selectedClient },
    });
  }

  useEffect(() => {
    getAllTrainerClientsFromToken()
      .then((response) => {
        setClients(response.data);
      })
      .catch((error) => {
        console.error('An error occurred while fetching the client list data: ', error);
      });
  }, []);

  const modal = (
    <Modal className="ReactModal__Overlay" isOpen={isModalOpen} onRequestClose={() => handleCloseModal()}>
        <div className="form-box">
          <h1>Add a New Client</h1>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="firstName"
              placeholder="First Name"
              value={clientToCreate.firstName}
              onChange={handleNewClientChange}
            />
            <input
              type="text"
              name="lastName"
              placeholder="Last Name"
              value={clientToCreate.lastName}
              onChange={handleNewClientChange}
            />
            <input
              type="text"
              name="email"
              placeholder="Client Email"
              value={clientToCreate.email}
              onChange={handleNewClientChange}
            />
            {message && <p>{message}</p>}
            <Button primary type="submit" disabled={isLoading}>Add New Client</Button>
          </form>
          <div className="grid-container">
            <Button secondary onClick={() => handleCloseModal()}>Cancel</Button>
          </div>
        </div>
      </Modal>
  )

  const renderedClientObjects = clients.map((client, index) => {
    return (
      <div className="box" key={client._id || index} onClick={() => handleClickOnClient(client)}>
        <div className="box-content">
          <div className="box-label">{client.firstName} {client.lastName}</div>
        </div>
        <div className="box-content">
          <div className="box-description">{client.email}</div>
        </div>
      </div>
    )
  });

  return (
    <div>
      <div className="header">
        <div className="header-title">Clients</div>
        <div><Button primary onClick={() => handleAddClientTapped()}>Add Client</Button></div>
      </div>
      <div className="boxes-wrapper">
        {renderedClientObjects.length > 0 ? renderedClientObjects : "No Clients"}
      </div>
      {modal}
    </div>
  );
};

export default ClientList;
