import React,  { useState } from "react";
import Meal from "./Meal";

function MealPlan() {
    const [meals, setMeals] = useState([]);

    const handleClick = (e, item) => {
        //TODO: add handle click logic
    };

    const renderedMeals = meals.map((item, index) => {
        return <Meal key={item.id} item={item} handleClick={() => handleClick(item)}> Meal Item </Meal>
        // return <ProgramItem key={item.id} item={item} handleClick={() => handleClick(item)} onBack={() => handleBackClick}/>
    });

    return (
        <div>
            <div className="header">
                <div className="header-title">Meal Plan</div>
            </div>
            <div className="boxes-wrapper">
                {renderedMeals.length > 0 ? renderedMeals : "No meals" }
            </div>
        </div>
    )
}

export default MealPlan;